import React, { useContext } from 'react'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import Icon from 'components/SvgIcon'
import Link from 'components/L10nLink'
import 'images/icons/crown.svg'
import 'images/icons/cup.svg'
import 'images/icons/checkbox.svg'
import 'images/icons/star.svg'

function Button(props) {
  const { children, link = '', variant, size, icon, className, onClick } = props // eslint-disable-line

  const btnLink = link || ''

  const classNames = [`btn`]

  if (variant) {
    classNames.push(`btn-${variant}`)
  }

  if (size) {
    classNames.push(`btn-${size}`)
  }

  if (className) {
    classNames.push(className)
  }

  const iconComponent =
    (icon && <Icon size={32} iconId={icon} svgClassName="icon-btn" />) || null

  const linkInner = (
    <>
      <span
        className="btn-inner"
        dangerouslySetInnerHTML={{ __html: children }}
      />
      {iconComponent}
    </>
  )

  if (onClick) {
    return (
      <button
        className={classNames.join(` `)}
        onClick={e => {
          e.preventDefault()
          onClick()
        }}
      >
        {linkInner}
      </button>
    )
  }

  if (btnLink.startsWith(`#`)) {
    // if (btnLink === '#nominate') {
    //   return (
    //     <button
    //       className={classNames.join(` `)}
    //       onClick={() => {
    //         console.log('clicked')
    //         nominatePopupToggle()
    //       }}
    //     >
    //       {linkInner}
    //     </button>
    //   )
    // }

    return (
      <AnchorLink
        to={btnLink}
        className={classNames.join(` `)}
        onClick={onClick}
      >
        {linkInner}
      </AnchorLink>
    )
  }

  return (
    <Link to={btnLink} className={classNames.join(` `)} onClick={onClick}>
      {linkInner}
    </Link>
  )
}

export default Button
