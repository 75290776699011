import React from 'react'
import { graphql } from 'gatsby'
import Button from 'components/Button'
import posed from 'react-pose'
import VisibilitySensor from 'react-visibility-sensor'
import { renderAstPosedConfig } from 'components/AstRenderer'
import {
  enhanceWithMountState,
  enhanceWithVisibilityState,
} from 'components/enhancers'
import { compose } from 'recompose'

const ArticleHeading = posed.h2(renderAstPosedConfig)

const ArticleBlock = React.forwardRef(
  ({ html = '', title = '', ImageComponent }, ref) => {
    return (
      <article
        ref={ref}
        className="entry block md:bg-grey-lightest text-black no-underline md:hover:bg-primary md:hover:text-white"
      >
        <header className="flex flex-col-reverse mb-4 md:block md:mb-0">
          {ImageComponent && <ImageComponent />}

          {title ? (
            <ArticleHeading
              className="entry-title mb-6 md:px-6 md:my-6"
              dangerouslySetInnerHTML={{ __html: title }}
            />
          ) : null}
        </header>

        <div className="entry-summary md:px-6 md:pb-6">{html}</div>
      </article>
    )
  }
)

const ArticleBlockPosed = posed(ArticleBlock)({
  hoverable: true,
  enter: {
    beforeChildren: true,
    staggerChildren: 400,
  },
})

const colEnterPose = {
  opacity: 1,
  x: 0,
  beforeChildren: true,
  staggerChildren: 400,
  transition: {
    duration: 500,
  },
}

const ArticleCol = posed.div({
  enter: {
    ...colEnterPose,
    delayChildren: ({ i }) => i * 2 * 400 + 400,
  },

  exit: {
    opacity: 0,
    x: ({ i }) => {
      const even = (i + 1) % 2 === 0

      return `${50 * (even ? 1 : -1)}%`
    },
  },
})

// const ArticleColRight = posed.div({
//   enter: {
//     ...colEnterPose,
//     delayChildren: 1200,
//   },

//   exit: {
//     opacity: 0,
//     x: '50%',
//   },
// })

const SectionPosed = posed.section({
  enter: {
    opacity: 1,
    staggerChildren: 600,
    beforeChildren: true,
  },
  exit: {
    opacity: 0,
  },
  transition: {
    duration: 1,
  },
})

const ArticlesBase = ({
  articles,
  articlesBtnLink,
  articlesBtnText,
  mounted,
  becameVisible,
  handleVisibilityChange,
}) => {
  const sectionPose = mounted && becameVisible ? `enter` : `exit`

  return (
    <VisibilitySensor
      onChange={handleVisibilityChange}
      partialVisibility={true}
      minTopValue={200}
    >
      <SectionPosed
        initialPose="exit"
        pose={sectionPose}
        id="the-project"
        className="py-8 lg:py-16"
      >
        <div className="container px-10 md:px-4 overflow-hidden">
          <div className="flex flex-wrap items-stretch md:-mx-2 lg:-mx-8">
            {articles &&
              articles.map((article, idx) => (
                <ArticleCol
                  key={idx}
                  className="w-full overflow-hidden md:w-1/2 md:px-2 lg:px-8"
                  i={idx}
                >
                  <ArticleBlockPosed {...article} />
                  {/* <div className="clearfix h-full"> */}
                  {/* </div> */}
                </ArticleCol>
              ))}

            {/* <ArticleCol className="w-full overflow-hidden md:w-1/2 md:px-2 lg:px-8">
              <div className=" clearfix">
                <ArticleBlockPosed {...articles[1]} />
              </div>
            </ArticleCol> */}
          </div>

          {articlesBtnLink && articlesBtnText ? (
            <div className="text-center mt-10">
              <Button
                link={articlesBtnLink}
                size="lg"
                variant="violet"
                className="text-base leading-loose"
              >
                {articlesBtnText}
              </Button>
            </div>
          ) : null}
        </div>
      </SectionPosed>
    </VisibilitySensor>
  )
}

const Articles = compose(
  enhanceWithMountState,
  enhanceWithVisibilityState
)(ArticlesBase)

export default Articles

export const homeSectionArticlesFragment = graphql`
  fragment HomeSectionArticlesFragment on HomeYaml {
    articles {
      items {
        title
        excerptMd {
          childMarkdownRemark {
            htmlAst
          }
        }

        image {
          ... on File {
            childImageSharp {
              fluid(maxWidth: 662, maxHeight: 336, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }

            publicURL
          }
        }
      }

      buttonText
      buttonLink
    }
  }
`
