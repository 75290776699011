import React from 'react'
import rehypeReact from 'rehype-react'
import UiButton from 'components/Button'
import posed from 'react-pose'
// import { walkObject } from 'walk-object'
import { MarkdownLink } from 'components/L10nLink'

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: {
    'ui-button': UiButton,
    a: MarkdownLink,
  },
}).Compiler

export default renderAst

// const createElementWithPoseTextSplit = (...args) => {
//   const [, , children, poseProps = {}] = args

//   if (children && children.length) {
//     children.forEach((child, idx) => {
//       if (typeof child === 'string') {
//         const wrappedChild = {
//           type: 'split-text',
//           props: poseProps,
//           children: [child],
//         }

//         children[idx] = wrappedChild
//       }
//     })
//   }

//   return React.createElement.apply(this, args)
// }

export const renderAstPosedConfig = {
  enter: {
    y: 0,
    opacity: 1,
    transition: {
      type: 'spring',
      default: {
        duration: 300,
      },
    },
  },
  exit: { y: 50, opacity: 0 },
}

const PosedParagraph = posed.p(renderAstPosedConfig)
const NonEmptyPosedParagraph = props =>
  React.Children.count(props.children) ? <PosedParagraph {...props} /> : null

const renderAstWithTextPose = (ast, properties = {}) => {
  const posedConfig = renderAstPosedConfig

  const components = {
    'ui-button': UiButton,
    p: NonEmptyPosedParagraph,
    h1: posed.h1(posedConfig),
    h2: posed.h2(posedConfig),
    h3: posed.h3(posedConfig),
    li: posed.li(posedConfig),
  }

  const compiler = new rehypeReact({
    // createElement: (...args) => createElementWithPoseTextSplit.apply(this, args.concat([poseProps])),
    createElement: React.createElement,
    components,
  }).Compiler

  return compiler(ast)
}

export { renderAstWithTextPose }
