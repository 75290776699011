import React from 'react'

export default ({ iconId, svgClassName = '', size = 16 }) => {
  const svgAtts = {
    width: size,
    height: size,
    className: `svg-icon ${svgClassName}`,
  }

  return (
    <svg {...svgAtts}>
      <use href={`#${iconId}`} />
    </svg>
  )
}
