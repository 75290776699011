import React from 'react'
import classnames from 'classnames'

function HalfColumns({ left, right, className, ...props }) {
  return (
    <div
      className={classnames(`flex flex-wrap items-stretch lg:-mx-8`, className)}
      {...props}
    >
      <div className="w-full lg:w-1/2 md:px-2 lg:px-8">{left}</div>
      <div className="w-full lg:w-1/2 md:px-2 lg:px-8">{right}</div>
    </div>
  )
}

export default HalfColumns
